<template>
  <div>
    <b-card no-body class="shadow">
      <PaginatorTable responsive :fields="site_list_fields" :is-busy="bldgs === null" :hide-filter="bldgs.length < 10"
                      :hide-top-pagination="bldgs.length < 25" :sort-by="sort_by || default_sort_by"
                      :items="bldgs" :show_default_no_items_warning="false">
        <template v-slot:cell(name)="data">
          <b-link class="nowrap" :to="'/netdoc/sites/'+data.item.site_fq_name+'/'+data.item.number"><b>{{ data.value }}</b></b-link>
        </template>
        <template v-slot:cell(number)="data">
          <b-link class="nowrap" :to="'/netdoc/sites/'+data.item.site_fq_name+'/'+data.item.number"><b>{{ data.value }}</b></b-link>
        </template>
      </PaginatorTable>
      <div v-if="bldgs && bldgs.length === 0" class="font-italic text-center mb-3">
        {{ $t('components.bcd_list.no_bldgs_available') }}
      </div>
    </b-card>
  </div>
</template>

<script>

import PaginatorTable from '@/components/PaginatorTable.vue'

export default {
  name: 'BldgList',
  components: { PaginatorTable },
  watch: {
    filter: {
      immediate: true,
      async handler() {
        if (this.filter_callback !== null) {
          const result = await this.filter_callback(this.filter)
          if (result !== null) {
            this.filter = result
          }
        }
      }
    },
  },
  data() {
    return {
      filter: '',
      current_page: 1,
      per_page: 100,
      sort_by: null,
    }
  },
  computed: {
    site_list_fields() {
      return [
        {
          label: this.$t('views.nd.bldg.number'),
          key: 'number',
          sortable: true
        },
        {
          label: this.$t('views.nd.bldg.name'),
          key: 'name',
          sortable: true
        },
        {
          label: this.$t('views.nd.bldg.street'),
          key: 'street',
          sortable: true
        },
        {
          label: this.$t('views.nd.bldg.zip'),
          key: 'postal_code',
          sortable: true
        },
      ]
    }
  },
  props: {
    default_sort_by: {
      required: false,
      default() {
        return 'number'
      }
    },
    bldgs: {
      required: true,
      default() {
        return null
      }
    },
    filter_callback: {
      required: false,
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>
</style>
