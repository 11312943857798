<template>
  <div id="Site">
    <h1>{{ $tc('views.nd.netdoc.bldgs', 2) }} <span v-if="site">in {{ site.fq_name }}</span></h1>
    <LoadingPlaceholder :data="bldgs">
      <BldgList :bldgs="bldgs" />
    </LoadingPlaceholder>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/components/Loading.vue'
import netdocService from '@/api-services/netdoc.service'
import BldgList from '@/components/nd/BldgList.vue'

export default {
  name: 'NetdocSite',
  components: { BldgList, LoadingPlaceholder },
  data() {
    return {
      site: null,
      bldgs: null,
    }
  },
  async created() {
    // const response = await NdSite.list(this.$store.state, {})
    if (this.$route.params.site) {
      const site = await netdocService.getSite(this.$store.state, this.$route.params.site)
      this.site = site.data.site_list[0]
    } else {
      this.site = null
    }
    const bldgs = await netdocService.getBldgs(this.$store.state, this.$route.params.site)
    this.bldgs = bldgs.data.bldg_list
  }
}
</script>

<style scoped>

</style>
